import { trySetAccount } from "./localStorageOverride"
import React from "react"
import { render } from "react-dom"
import Core from "./external/core/Core"
import Account from "./external/core/Account"
import "array-flat-polyfill"

// if (process.env.NODE_ENV !== "production") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, { trackHooks: false });
// }
// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update");
//   whyDidYouUpdate(React);
// }

if (window.matchMedia("(prefers-color-scheme: dark)").matches) import("./basicDarkStyle.css").then(() => {})
else import("./basicStyle.css").then(() => {})

const account = new Account()
function renderApp() {
  const urlSearchParams = new URL(window.location.href).searchParams

  // TODO: Remove this arrigoId check when launched also logic in ArrigoIdLogin.js-component
  if (urlSearchParams.get("arrigoId")) {
    localStorage.setItem("arrigoId", "true")
    // ^--- We remove this value from local storage when showing the button in the ArrigoIdLogin.js-component
    window.location.href = window.location.origin + window.location.pathname + window.location.hash
    return
  }

  if (urlSearchParams.get("code")) {
    localStorage.setItem("oauth", urlSearchParams.get("code"))
    window.location.href = window.location.origin + window.location.pathname + urlSearchParams.get("state")

    return
  }

  render(
    <Core
      packages={[
        "eos",
        "eos-navigation",
        "eos-network",
        "eos-notifications",
        "eos-users",
        "eos-addmenu",
        "eos-collection",
        "eos-filemanager",
      ]}
      account={account}
    />,
    document.getElementById("root")
  )
}

trySetAccount().then(() => {
  if (typeof window.IntersectionObserver === "undefined") {
    import("intersection-observer").then(function () {
      renderApp()
    })
  } else {
    renderApp()
  }
})
